export class DateConverter {
	static DateAndTime(dateString) {
		var date = new Date(dateString);
		var dd = date.getDate();
		var mm = date.toLocaleString("en-us", { month: "numeric" });
		var yyyy = date.getFullYear();
		var time = date.toLocaleString("en-us", {
			hour: "numeric",
			minute: "numeric",
		});
		if (dd < 10) {
			dd = "0" + dd;
		}
		if (mm < 10) {
			mm = "0" + mm;
		}
		var d = yyyy+ "/" + mm + "/"+ dd + " " + time;
		return d;
	}

	static DateOnly(dateString) {
		var date = new Date(dateString);
		var dd = date.getDate();
		var mm = date.toLocaleString("en-us", { month: "numeric" });
		var yyyy = date.getFullYear();
		if (dd < 10) {
			dd = "0" + dd;
		}
		if (mm < 10) {
			mm = "0" + mm;
		}
		var d = yyyy+ "/" + mm + "/"+ dd;
		return d;
	}
}
