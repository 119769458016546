<template>
	<v-container id="dashboard" fluid tag="section">
		<loader :active="loaderActive" />
		<v-row>
			<v-col cols="12" sm="6" lg="3">
				<base-material-stats-card
					color="primary"
					class="customblack--text"
					icon="mdi-account-multiple"
					title="ITD Disbursements"
					:value="
						items.itdDisbursement
							? 'K' + items.itdDisbursement.toFixed(2)
							: '0.00'
					"
				/>
			</v-col>

			<v-col cols="12" sm="6" lg="3">
				<base-material-stats-card
					color="indigo"
					icon="mdi-account-star"
					title="ITD Interests"
					:value="
						items.itdInterests ? 'K' + items.itdInterests.toFixed(2) : '0.00'
					"
				/>
			</v-col>

			<v-col cols="12" sm="6" lg="3">
				<base-material-stats-card
					color="success"
					icon="mdi-briefcase"
					title="ITD Charges"
					:value="items.itdCharges ? 'K' + items.itdCharges.toFixed(2) : '0.00'"
				/>
			</v-col>

			<v-col cols="12" sm="6" lg="3">
				<base-material-stats-card
					color="orange"
					icon="mdi-desktop-classic"
					title="YTD Disbursement"
					:value="
						items.ytdDisbursement
							? 'K' + items.ytdDisbursement.toFixed(2)
							: '0.00'
					"
				/>
			</v-col>

			<v-col cols="12" lg="4">
				<base-material-chart-card
					:data="monthlyDisbursementChart.data"
					:options="monthlyDisbursementChart.options"
					:responsive-options="monthlyDisbursementChart.responsiveOptions"
					color="primary"
					hover-reveal
					type="Bar"
				>
					<template v-slot:reveal-actions>
						<v-tooltip bottom>
							<template v-slot:activator="{ attrs, on }">
								<v-btn v-bind="attrs" color="info" icon v-on="on">
									<v-icon color="info"> mdi-refresh </v-icon>
								</v-btn>
							</template>

							<span>Refresh</span>
						</v-tooltip>

						<v-tooltip bottom>
							<template v-slot:activator="{ attrs, on }">
								<v-btn v-bind="attrs" light icon v-on="on">
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
							</template>

							<span>Change Date</span>
						</v-tooltip>
					</template>

					<h4 class="card-title font-weight-light mt-2 ml-2">
						Monthly Disbursement
					</h4>

					<p
						class="d-inline-flex font-weight-light ml-2 mt-1 customblack--text"
					>
						for the year {{ new Date().getFullYear() }}
					</p>

					<template v-slot:actions>
						<span class="caption customblack--text font-weight-light"
							>Peak: <b>K{{ monthlyHigh.toFixed(2) }}</b></span
						>
						<v-icon color="green" class="mr-1" small> mdi-arrow-up </v-icon>
						<span class="caption customblack--text font-weight-light"
							>Low: <b>K{{ monthlyLow.toFixed(2) }}</b></span
						>
						<v-icon color="red" class="mr-1" small> mdi-arrow-down </v-icon>
					</template>
				</base-material-chart-card>
			</v-col>

			<v-col cols="12" lg="4">
				<base-material-chart-card
					:data="dailyDisbursementChart.data"
					:options="dailyDisbursementChart.options"
					color="success"
					hover-reveal
					type="Line"
				>
					<template v-slot:reveal-actions>
						<v-tooltip bottom>
							<template v-slot:activator="{ attrs, on }">
								<v-btn v-bind="attrs" color="info" icon v-on="on">
									<v-icon color="info"> mdi-refresh </v-icon>
								</v-btn>
							</template>

							<span>Refresh</span>
						</v-tooltip>

						<v-tooltip bottom>
							<template v-slot:activator="{ attrs, on }">
								<v-btn v-bind="attrs" light icon v-on="on">
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
							</template>

							<span>Change Date</span>
						</v-tooltip>
					</template>

					<h4 class="card-title font-weight-light mt-2 ml-2">
						Daily Disbursement
					</h4>

					<p
						class="d-inline-flex font-weight-light ml-2 mt-1 customblack--text"
					>
						<!-- <v-icon color="green" small> mdi-arrow-up </v-icon> -->
						<span class="green--text">1day</span>&nbsp; spaced disbursement
						summary
					</p>

					<template v-slot:actions>
						<span class="caption customblack--text font-weight-light"
							>Peak: <b>K{{ dailyHigh.toFixed(2) }}</b></span
						>
						<v-icon color="green" class="mr-1" small> mdi-arrow-up </v-icon>
						<span class="caption customblack--text font-weight-light"
							>Low: <b>K{{ dailyLow.toFixed(2) }}</b></span
						>
						<v-icon color="red" class="mr-1" small> mdi-arrow-down </v-icon>
					</template>
				</base-material-chart-card>
			</v-col>

			<v-col cols="12" lg="4">
				<base-material-chart-card
					:data="hourlyDisbursementChart.data"
					:options="hourlyDisbursementChart.options"
					hover-reveal
					color="indigo"
					type="Line"
				>
					<template v-slot:reveal-actions>
						<v-tooltip bottom>
							<template v-slot:activator="{ attrs, on }">
								<v-btn v-bind="attrs" color="info" icon v-on="on">
									<v-icon color="info"> mdi-refresh </v-icon>
								</v-btn>
							</template>

							<span>Refresh</span>
						</v-tooltip>

						<v-tooltip bottom>
							<template v-slot:activator="{ attrs, on }">
								<v-btn v-bind="attrs" light icon v-on="on">
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
							</template>

							<span>Change Date</span>
						</v-tooltip>
					</template>

					<h3 class="card-title font-weight-light mt-2 ml-2">
						Hourly Disbursements
					</h3>

					<p
						class="d-inline-flex font-weight-light ml-2 mt-1 customblack--text"
					>
						<span class="green--text">3hour</span>&nbsp; spaced disbursement
						summary
					</p>

					<template v-slot:actions>
						<span class="caption customblack--text font-weight-light"
							>Peak: <b>K{{ hourlyHigh.toFixed(2) }}</b></span
						>
						<v-icon color="green" class="mr-1" small> mdi-arrow-up </v-icon>
						<span class="caption customblack--text font-weight-light"
							>Low: <b>K{{ hourlyLow.toFixed(2) }}</b></span
						>
						<v-icon color="red" class="mr-1" small> mdi-arrow-down </v-icon>
					</template>
				</base-material-chart-card>
			</v-col>

			<v-col cols="12" md="12">
				<base-material-card color="primary" class="px-5 py-3">
					<template v-slot:heading>
						<div class="display-2 font-weight-dark lightgray--text">
							Quick Report
							<button class="float-right">Download</button>
							<v-spacer />
							<v-text-field
								v-model="search"
								append-icon="mdi-magnify"
								label="Search"
								single-line
								hide-details
								color="lightgray"
							/>
						</div>

						<div class="subtitle-1 font-weight-light"></div>
					</template>
					<v-card-text>
						<v-data-table
							:headers="headers"
							:items="summariesWithFormatedValues"
							:search="search"
							:loading="loaderActive"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							class="elevation-1"
							loading-text="Loading... Please wait"
						/>
					</v-card-text>
				</base-material-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { mapState } from "vuex";
	import store from "../../../../store";
	import loaderMixin from "../../../../mixins/loader";
	import { DateConverter } from "../../../../utils/dateUtil";
	import {findHighestLowest} from "../../../../utils/highAndLow"
	export default {
		name: "DashboardDashboard",
		components: {
			Loader: () => import("../../../../components/Loader"),
		},
		mixins: [loaderMixin],
		data() {
			return {
				dailyDisbursementChart: {
					data: {
						labels: ["M", "T", "W", "T", "F", "S", "S"],
						series: [],
					},
					options: {
						lineSmooth: this.$chartist.Interpolation.cardinal({
							tension: 0,
						}),
						low: 0,
						high: this.dailyHigh, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
						chartPadding: {
							top: 0,
							right: 0,
							bottom: 0,
							left: 0,
						},
					},
				},
				hourlyDisbursementChart: {
					data: {
						labels: [
							"00hs",
							"03hs",
							"06hs",
							"09hs",
							"12hs",
							"15hs",
							"18hs",
							"21hs",
							"24hs",
						],
						series: [],
					},
					options: {
						lineSmooth: this.$chartist.Interpolation.cardinal({
							tension: 0,
						}),
						low: 0,
						high: this.hourlyHigh, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
						chartPadding: {
							top: 0,
							right: 0,
							bottom: 0,
							left: 0,
						},
					},
				},
				monthlyDisbursementChart: {
					data: {
						labels: [
							"Ja",
							"Fe",
							"Ma",
							"Ap",
							"Mai",
							"Ju",
							"Jul",
							"Au",
							"Se",
							"Oc",
							"No",
							"De",
						],
						series: [],
					},
					options: {
						axisX: {
							showGrid: false,
						},
						low: 0,
						high: this.monthlyHigh,
						chartPadding: {
							top: 0,
							right: 5,
							bottom: 0,
							left: 0,
						},
					},
					responsiveOptions: [
						[
							"screen and (max-width: 640px)",
							{
								seriesBarDistance: 5,
								axisX: {
									labelInterpolationFnc: function(value) {
										return value[0];
									},
								},
							},
						],
					],
				},
				monthlyHigh:"",
				monthlyLow:"",
				responsePayload: {},
				search: "",
				sortBy: "date",
				sortDesc: true,
				headers: [
					{
						sortable: true,
						text: "Date",
						value: "date",
					},
					{
						sortable: true,
						text: "Disbursement",
						value: "totalDisbursements",
					},
					{
						sortable: true,
						text: "Interest",
						value: "totalInterests",
					},
					{
						sortable: true,
						text: "Charge",
						value: "totalCharges",
					},
					{
						sortable: true,
						text: "Total",
						value: "totalAmount",
					},
					{
						sortable: true,
						text: "Average",
						value: "averageDailyDisbursements",
					},
					{
						sortable: true,
						text: "Requests",
						value: "successfulRequests",
					},
				],
				items: [],
			};
		},
		created() {
			this.initialize();
		},

		methods: {
			async initialize() {
				this.showLoader();
				await this.$store.dispatch("dashboard/fetchDashboardData"),
					(this.items = store.state.dashboard.dashboardData.data);
				this.dailyDisbursementChart.data.series.push(
					this.items.dailyDisbursement.map((item) => item.amount)
				);
				this.hourlyDisbursementChart.data.series.push(
					this.items.hourlyDisbursement.map((item) => item.amount)
				);
				this.monthlyDisbursementChart.data.series.push(
					this.items.monthlyDisbursement.map((item) => item.amount)
				);
				this.responsePayload = store.state.dashboard.dashboardData;
				var monthlyHL = findHighestLowest(this.items.monthlyDisbursement.map((item) => item.amount));
				var dailyHL = findHighestLowest(this.items.dailyDisbursement.map((item) => item.amount));
				var hourlyHL = findHighestLowest(this.items.hourlyDisbursement.map((item) => item.amount));
				this.monthlyHigh  = monthlyHL.highest;
				this.monthlyLow = monthlyHL.lowest;
				this.dailyHigh  = dailyHL.highest;
				this.dailyLow = dailyHL.lowest;
				this.hourlyHigh  = hourlyHL.highest;
				this.hourlyLow = hourlyHL.lowest;
				this.hideLoader();
			},
		},
		computed: {
			summariesWithFormatedValues() {
				console.log("SUMMARIES ===> ", this.items.summaries);
				return this.items.summaries.map((item) => ({
					...item,
					date: DateConverter.DateOnly(item.date),
					totalDisbursements: "K" + item.totalDisbursements.toFixed(2),
					totalInterests: "K" + item.totalInterests.toFixed(2),
					totalCharges: "K" + item.totalCharges.toFixed(2),
					averageDailyDisbursements:
						"K" + item.averageDailyDisbursements.toFixed(2),
					totalAmount: "K" + item.totalAmount.toFixed(2),
				}));
			},
			...mapState(["user"]),
		},

		mounted() {
			document.title = "Home | Kredwise";
			store.subscribe((mutation, state) => {
				if (mutation.type === "user/updateIsLoggedIn") {
					if (!state.user.isLoggedIn) {
						this.$router.push("/");
					}
				}
			});
		},
	};
</script>
